.privacy__overlay {
    display: flex;
    align-items: center;
    justify-content: center;

}

.privacy__overlay > div {
    max-width: 970px;
    text-align: justify;
}
