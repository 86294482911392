.content-carousel {
    width: 100%;
    height: 100%;
    margin-top: 100px;
}

.content-carousel img {
    width: 100%;
    height: 100%;
    text-align: center;
}
