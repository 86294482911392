body {
    margin: 0;
    font-family: 'Commissioner', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Commissioner', sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #F1F1F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: rgba(138, 128, 128, 0.39);
}


:root {
    --mainColor: #5A6E7F;
    --secondaryColor: #97D5C9;
    --thirdColor: #000;

    --fontMain: 'Averta', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}


@font-face {
    font-family: 'Averta';
    font-style: normal;
    font-weight: 400;
    src: local("static"), url('./fonts/Averta/Averta.otf') format('opentype');
}

@font-face {
    font-family: 'Averta';
    font-style: normal;
    font-weight: 500;
    src: local("static"), url('./fonts/Averta/Averta-Semibold.otf') format('opentype');
}


body {
    margin: 0;
    font-family: var(--fontMain);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
