.functionality-description-1 {
    position: relative;
    display: flex;
    align-items: center;
    margin: 50px 0 0 0;
    padding: 0;

}

.functionality-description-1__column-1 {
    flex: 0.4;

}

@media only screen and (max-width: 1150px) {
    .functionality-description-1 {
        display: flex;
        flex-direction: column;
        padding: 0
    }

    .functionality-description-2 {
        display: flex;
        flex-direction: column-reverse;
    }

    .functionality-description-1__column-1 {
        width: 450px;
    }

    .functionality-description-1__column-2 {
        margin-bottom: 20px;
    }

    .functionality-description-2__column-2 {
        width: 450px;
    }
}

/*@media only screen and (max-width: 1150px) {*/
/*    .functionality-description-1__column-1 {*/
/*        min-width: 100%;*/
/*        min-height: 550px;*/
/*        background-image: url('../img/Untitled-2.svg');*/
/*        background-repeat: no-repeat;*/
/*    }*/

/*    .functionality-description-1__column-2 {*/
/*        margin-bottom: 20px;*/
/*    }*/

/*    .functionality-description-2__column-2 {*/
/*        min-width: 100%;*/
/*        min-height: 750px;*/
/*        background-image: url('../img/Untitled-3.svg');*/
/*        background-repeat: no-repeat;*/
/*    }*/
/*}*/


@media only screen and (max-width: 580px) {
    .functionality-description-1__column-1 {
        width: 100%;
    }

    .functionality-description-2__column-2 {
        width: 100%;
    }
}


.functionality-description-1__column-2 {
    flex: 0.6;
    text-align: center;
}

.functionality-description-1__column-2 h2 {
    font-size: 23px;
    font-weight: 400;
    font-style: italic;
    text-align: justify;
    color: #484848;
    margin-inline: 60px;
}

.functionality-description-1__column-2 h2::after {
    display: block;
    width: 9%;
    margin: 0;
    padding-top: 15px;
    content: "";
    border-bottom: 2px solid var(--mainColor, #F91942);
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
}

.functionality-description-1__column-2 h2:hover::after {
    transform: scaleX(0.5);
}

.functionality-description-1__column-2 p {
    margin: 20px 80px 0 80px;
    font-size: 23px;
    line-height: 30px;
    text-align: justify;
    color: #484848;
}

/*.functionality-description-1__column-2 li {*/
/*    padding: 10px 0 4px 0;*/
/*}*/

.functionality-description-2 {
    display: flex;
    align-items: center;
}

.functionality-description-2__column-1 {
    flex: 0.6;
}

.functionality-description-2__column-1 h2 {
    font-size: 24px;
    font-weight: 400;
    font-style: italic;
    text-align: justify;
    color: #484848;
    margin-inline: 60px;
}

.functionality-description-2__column-1 h2::after {
    display: block;
    width: 9%;
    margin: 0;
    padding-top: 15px;
    content: "";
    border-bottom: 2px solid var(--mainColor, #F91942);
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
}

.functionality-description-2__column-1 h2:hover::after {
    transform: scaleX(0.5);
}

.functionality-description-2__column-1 p {
    margin: 20px 80px 0 80px;
    font-size: 23px;
    line-height: 30px;
    text-align: justify;
    color: #484848;
}

.functionality-description-1__column-2 li,
.functionality-description-3__column-2 li,
.functionality-description-2__column-1 li {
    padding: 10px 0 4px 25px;
}

.functionality-description-2__column-2 {
    flex: 0.4;
}

.functionality-description-3 {
    display: flex;
    align-items: center;

}

.functionality-description-3__column-1 {
    flex: 0.4;
}


.functionality-description-3__column-2 {
    flex: 0.6;
    text-align: center;
}

.functionality-description-3__column-2 h2 {
    font-size: 23px;
    font-weight: 400;
    font-style: italic;
    text-align: justify;
    color: #484848;
    margin-inline: 60px;
}

.functionality-description-3__column-2 h2:after {
    display: block;
    width: 9%;
    margin: 0;
    padding-top: 15px;
    content: "";
    border-bottom: 2px solid var(--mainColor, #F91942);
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
}

.functionality-description-3__column-2 h2:hover::after {
    transform: scaleX(0.5);
}

.functionality-description-3__column-2 p {
    margin: 20px 80px 0 80px;
    font-size: 23px;
    line-height: 30px;
    text-align: justify;
    color: #484848;
}

.left-right-flex > div:not(:first-of-type) {
    margin-top: 60px;
}

.left-right-flex {
    margin-top: 60px;
    padding-bottom: 60px;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;;
}

@media only screen and (max-width: 1150px) {
    .functionality-description-3 {
        display: flex;
        flex-direction: column;
        padding: 0
    }

    .functionality-description-3__column-1 {

        width: 450px;
    }
}


@media only screen and (max-width: 580px) {
    .functionality-description-3__column-1 {
        width: 100%;

    }
}


