.bottom-up {
    position: relative;
}

.bottom-up .bottom-up__svg-icon {
    position: fixed;
    right: 40px;
    bottom: 50px;
    width: 45px;
    height: 45px;
    color: white;
    border: 1px solid var(--mainColor, #F91942);
    border-radius: 50%;
    background-color: var(--mainColor, #F91942);
    cursor: pointer;
    animation: fadeInOut ease 1.1s forwards;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

