.flexBoxes_container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}



