.recommended__wrapper {
    margin: 90px 0 40px;
}

.recommended__lines_4or5 {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.recommended__lines_4or5 > div {
    position: relative;
    flex: 0 1 25%;
    height: 350px;
    margin: 10px;
    overflow: hidden;
    border-radius: 6px;
}

@media screen and (max-width: 767px) {
    .recommended__lines_4or5 {
        flex-wrap: wrap;
    }

    .recommended__lines_4or5 > div {
        flex: 0 1 calc(50% - 20px);
        height: 250px;


    }

    .recommended__lines_4or5 div.recommended__card__content {
        font-size: 17px;
        line-height: 18px;
    }

    .recommended__lines_4or5 > div:nth-of-type(5) {
        flex: 0 1 100%;

    }

}

