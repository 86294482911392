.clients-container {
    width: 100%;
    height: 280px;
    margin: 80px 0 0 0;
}

.clients-container h2 {
    margin: 0 0 50px 0;
    font-size: 27px;
    text-align: center;
}

.clients-container h2::after {
    display: block;
    width: 4%;
    margin: 0 auto;
    padding-top: 15px;
    content: "";
    border-bottom: 2px solid var(--mainColor, #F91942);
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
}

.clients-container h2:hover::after {
    transform: scaleX(0.3);
}


.clients-container .react-multi-carousel-list {
    position: unset;
    max-width: calc(100% - 48px * 4);

    margin-inline: auto;
}

.clients-container .react-multi-carousel-list button:first-of-type {
    left: 24px;
}

.clients-container .react-multi-carousel-list button:last-of-type {
    right: 24px;
}

.clients-container .react-multi-carousel-dot-list {
    display: none;
}
