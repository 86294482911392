.functionality-content {
    width: 100%;
    height: 100%;
    margin: 70px 50px 0 0;
}

.functionality-content h2 {
    margin: 0;
    font-size: 27px;

    text-align: center;
}

.maxWidth__class {
    max-width: 1500px;
    margin-inline: auto;
}

/*Custom underline*/
.functionality-content h2::after {
    display: block;
    width: 5%;
    margin: 0 auto;
    padding-top: 15px;
    content: "";
    border-bottom: 2px solid var(--mainColor, #F91942);
    transition: transform 0.3s ease-in-out;
    transform: scaleX(1);
}

.functionality-content h2:hover::after {
    transform: scaleX(0.3)
}

.wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}

.wrapper-content {
    width: 220px;
    height: 220px;
    margin: 10px;
    padding: 25px;
    text-align: center;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 0 8px 8px rgb(0 0 0 / 5%);
    cursor: pointer;
    will-change: tansform;
    transition: transform 500ms;
}

.flex_box {
    flex: calc(20% - 50px);
    min-width: 175px;
}

.wrapper-content:hover {
    transform: translateY(-10px);
}

.wrapper-content svg,
.wrapper-content img {
    width: 54px;
    height: 54px;
}

.wrapper-content h4 {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #484848;
}


.large-box {
    /*width: calc(50% - 25px - 25px) !important;*/

    min-width: 410px;

}

.large-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.large-box > div {
    width: 100%;
    margin-left: 50px;
    text-align: left;
}

.large-box ul {
    padding: 0;
    list-style-position: inside;
    color: var(--mainColor);
}



