.contact-container {
  display: flex;
  /*height: 250px;*/
  margin: 70px 0 0 0;
  padding: 50px 0 0 50px;
  border: 1px solid #eeeeee;
  background-color: white;
}

.contact-container__column-1 {
  margin-bottom: 60px;
}

.contact-container__column-2 > div {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.contact-container__column-2 > div > div:first-of-type {
  margin-right: 20px;
}

.contact-container__column-2 > div > div {
  flex: 50%;
}

@media screen and (max-width: 800px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    min-height: 290px;
    padding-top: 20px;
    padding-left: 25px;
    background-color: white;
  }
}

.contact-container__column-1 {
  position: relative;
  flex: 0.25;
}

.contact-container__column-1 svg,
.contact-container__column-1 img {
  width: 250px;
}

.contact-container__reference {
  margin-top: 15px;
}

.contact-container__reference a {
  padding: 0 13px 0 13px;
  font-size: 16px;
  /*font-weight: bold;*/
  text-decoration: none;
  color: var(--mainColor, #f91942);
}

@media screen and (max-width: 700px) {
  .contact-container__reference a {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .contact-container__reference a {
    font-size: 13px;
  }
}

.contact-container__column-2 {
  position: relative;
  flex: 0.75;
  padding-inline: 20px;
}

.contact-container__details h4 {
  margin: 0;
  font-size: 22px;
}

.contact-container__details p {
  font-size: 17px !important;
}

.contact-container__details span {
  /*font-weight: bold;*/
  color: var(--mainColor, #f91942);
}

@media screen and (max-width: 700px) {
  .contact-container__details h4 {
    font-size: 19px;
  }

  .contact-container__details p {
    font-size: 14px;
  }

  .contact-container__copyrights p {
    font-size: 15px;
  }
}

@media screen and (max-width: 450px) {
  .contact-container {
    margin: 0;
  }

  .contact-container__column-1 img {
    width: 220px;
  }

  .contact-container__details h4 {
    font-size: 18px;
  }

  .contact-container__details p {
    font-size: 13px;
  }
}

.contact-container__copyrights {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: white;
}

.contact-container__copyrights p {
  margin: 0;
  font-weight: bold;
  color: #a4a4a4;
}
