.recommended__card::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.3;
    background-color: #222;
    z-index: 1;
}

.recommended__card {
    cursor: pointer;
}

.recommended__card img {
    width: 100%;
    height: 100%;
    will-change: transform;
    transition: transform 1.5s cubic-bezier(.19, 1, .22, 1);
    transform: scale(1.1);
    object-fit: cover;
}


.recommended__card__content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;

    text-align: center;
    color: white;
    z-index: 2;
    inset: 0 0 0 0;
}


@media (hover: hover) {
    .recommended__card:hover img {
        transform: scale(1);
    }
}
