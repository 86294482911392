.container {
  width: 100%;
  height: auto;
  background-color: grey;
  overflow-x: hidden;
}

.header {
  width: 100%;
  padding: 10px;
  background-color: aqua;
  height: 80px;
}

.main {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 50px;
  background-image: url('./../img/cover.png');
}

.card {
  width: 400px;
  height: 370px;
  position: relative;
  border: 1px solid black;
  margin-bottom: 50px;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  background-color: white;
}

.card_ins {
  width: 400px;
  height: 370px;
  /* background-image: url('./logo.svg'); */
  background-size: cover;
  transition: all .3s ease-in-out;
}

.card:hover .card_ins {
  transform: scale(1.3);
  background-color: black;
  opacity: 0.7;
}

.text {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 10px;
  visibility: hidden;
  color: white;
  transform: scale(1.2); 
  transition: all .3s ease-in-out;
  font-weight: bold;
  text-align: center;
}

.card:hover .text {
  visibility: visible;
  transform: scale(1);   
}

.footer {
  width: 100%;
  height: 30px;
  background-color: coral;
}

@media screen and (max-width: 1305px) {
  .main {
    height: auto;
    padding-top: 117px;
  }
}